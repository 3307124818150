.OurTeamStyle_timeline_container__aLIdK {
  position: relative;
}

.OurTeamStyle_timeline_container__aLIdK:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  bottom: 3px;
  width: 1px;
  background: #fff;
}

.OurTeamStyle_timeline_container__aLIdK:after {
  content: "";
  bottom: 3px;
  width: 14px;
  position: absolute;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #fff;
  left: -7px;
}

.OurTeamStyle_timeline_container__aLIdK .OurTeamStyle_timeline_item__cqVaG {
  height: auto;
  position: relative;
  padding-left: 20px;
  text-align: left;
}

.OurTeamStyle_timeline_container__aLIdK
  .OurTeamStyle_timeline_item__cqVaG:before {
  content: "";
  left: -7px;
  top: 11px;
  width: 14px;
  position: absolute;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #fff;
}

.OurTeamStyle_timeline_container__aLIdK
  .OurTeamStyle_timeline_item__cqVaG:nth-last-child(2) {
  height: auto;
}

.OurTeamStyle_timeline_container__aLIdK .OurTeamStyle_timeline_being__Z_aWo {
  padding-left: 0px;
}

@media (min-width: 768px) {
  .OurTeamStyle_timeline_container__aLIdK {
    max-width: 100%;
    overflow: visible;
    margin: auto;
    padding-bottom: 0px;
  }

  .OurTeamStyle_timeline_container__aLIdK:before {
    /* left: 50%; */
    top: 11px;
    bottom: 34px;
  }

  .OurTeamStyle_timeline_container__aLIdK:after {
    bottom: 34px;
    /* left: calc(50% - 7px); */
  }

  .OurTeamStyle_timeline_container__aLIdK .OurTeamStyle_timeline_item__cqVaG {
    width: 100%;
    clear: both;
  }

  .OurTeamStyle_timeline_container__aLIdK
    .OurTeamStyle_timeline_item__cqVaG:nth-child(2n-1) {
    float: none;
    padding-left: 85px;
  }
  .OurTeamStyle_timeline_container__aLIdK
    .OurTeamStyle_timeline_item__cqVaG:nth-child(2n-1):before {
    left: -7px;
  }

  .OurTeamStyle_timeline_container__aLIdK
    .OurTeamStyle_timeline_item__cqVaG:nth-child(2n) {
    float: none;
    text-align: left;
    padding-right: 0px;
    padding-left: 86px;
  }

  .OurTeamStyle_timeline_container__aLIdK
    .OurTeamStyle_timeline_item__cqVaG:nth-child(2n):before {
    left: -7px;
    right: -7px;
  }
  .OurTeamStyle_timeline_container__aLIdK
    .OurTeamStyle_timeline_item__cqVaG:nth-last-child(2) {
    /* height: 120px; */
  }

  .OurTeamStyle_timeline_container__aLIdK .OurTeamStyle_timeline_being__Z_aWo {
    position: relative;
    top: 14px;
    text-align: left;
    padding-left: 0px;
  }
}
